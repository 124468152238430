
import { KeepAlive } from '@/utils/decorators'
import { Vue, Component } from 'vue-property-decorator'
import Examine from '@/views/assetScheduling/transfer/Examine.vue'

@Component({
  name: 'DisposalDetail',
  components: { Examine }
})
@KeepAlive
export default class DisposalDetail extends Vue {
  tableData = []
  total = 0
  page = 1
  size = 10

  dialogVisible = false
  orderList = ['发起', '处置负责人审批']
  private showDialog = false
  private id = ''

  formData = {
    orderId: '', // 单据id
    orderNumber: '', // 单号
    orderType: '', // 单据类型1入库单2领用单3退还单4调拨单5盘库单6处置单
    userId: '', // 操作人id
    userName: '', // 操作人姓名
    remarks: '', // 备注
    projectId: '', // 所属项目
    projectName: '', // 所属项目
    orderDate: '', // 工单日期
    disposalType: '', // 处置类型1报废2出售
    totalDisposalAmount: '', // 处置总金额
    disposalCosts: '', // 处置费用
    disposalReason: '', // 处置原因
    fileList: [], // 附件
    assetDisposeProcessList: []
  }

  get orderId () {
    return this.$route.query.orderId as string
  }

  get useId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  created () {
    this.loadData()
    this.loadAssets()
    this.id = this.orderId || ''
  }

  loadData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetHandleByOrderId, {
      orderId: this.orderId
    }).then(res => {
      this.formData = res
    })
  }

  loadAssets () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetImageByPage, {
      orderId: this.orderId,
      page: this.page,
      size: this.size
    }).then(res => {
      console.log('详情', res)
      this.tableData = res.list
      this.total = res.total
    })
  }

  isShowExamine (row: any) {
    return (row.disposeProcessState === '1' && this.useId === row.disposeId)
  }

  success () {
    this.loadData()
    this.loadAssets()
  }
}
